import { get, onValue, ref } from 'firebase/database';
import { Link } from 'gatsby';
import React from 'react';
import Cookies from 'universal-cookie';
import DomainEntry from './DomainEntry';

class Domains extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
          yolo: null,
          tabIndex: 0,
        }
    }
      
    componentDidMount = () => {
        const { user, database } = this.props;
        
        const refi = ref(database, 'users/' + user.uid + '/domains/')

        onValue(refi, (snapshot) => {
            if (snapshot.exists()) {
                const valcsi = snapshot.val()
                //console.log("onValue",valcsi);
                this.setState({yolo: valcsi})
            } else {
                //console.log("No data available");
            }

        })

        /*
        get(refi).then((snapshot) => {
            if (snapshot.exists()) {
                const valcsi = snapshot.val()
                console.log(valcsi);
                this.setState({yolo: valcsi})

            } else {
                console.log("No data available");
                this.setState({yolo: "nono"})
            }
        }).catch((error) => {
            console.error(error);
        });
        */

    }

    render() {
        const yolo = this.state.yolo
        let listOfDomains = []

        const cookies = new Cookies()
        let cookieDoNotForceOpen = cookies.get("doNotForceOpen") //false by default to make it forceOpen first 
        if (cookieDoNotForceOpen === undefined) {
            cookieDoNotForceOpen = false
        }
        //console.log("cookieDoNotForceOpen", cookieDoNotForceOpen);

        if (yolo) {
            const domains = Object.keys(yolo)

            domains.forEach( (item, index) => {
                //console.log("item index", item, index);
                listOfDomains.push(<DomainEntry key={item} domainKey={item} index={index} forceOpen={cookieDoNotForceOpen === false && domains.length === 1} domainInfo={yolo[item]} database={this.props.database} user={this.props.user} />)
            })

            /*
            domains.forEach( (item, index) => {
                //listOfDomains.push(<DomainEntry key={item} domainKey={item} index={index+1} domainInfo={yolo[item]} database={this.props.database} user={this.props.user} />)
            })
            */

            return (
                <>
                <div className="overflow-x-auto">
                    <div className="collapse-title text-l font-medium flex flex-row justify-start rounded-t-lg bg-base-300 border-b-[2px] border-b-primary">
                        <div className="basis-3/8 min-w-[40%] text-center">
                            DOMAIN
                        </div>
                        <div className="grow-0 basis-1/8 shrink-0  min-w-[10%] text-center">
                            -->
                        </div>
                        <div className="basis-3/8 min-w-[40%] text-center">
                            REDIRECT
                        </div>
                        <div className="grow-0 basis-1/8 shrink-0 min-w-[10%] text-center">
                            ACTIONS
                        </div>
                    </div>
                        
                    {listOfDomains}

                </div>
                <div className="card-actions justify-end mt-16">
                    <div className="alert alert-info shadow-lg w-fit">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current flex-shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                            <div className="mr-16">
                            Would you like a <b>custom domain name</b>? (e.g. <u>{this.props.user.displayName.replace(" ","").toLowerCase()}.redirectto.me</u>)<br/>
                            Or do you need <b>more than one redirect domain with SSL</b>?
                            </div>
                        </div>
                        <div className="flex-none">
                            <div className='indicator mr-8'>
                                <button className="btn btn-neutral">
                                    <span className="indicator-item badge badge-warning text-[0.8em]">beta</span> 
                                    <Link to='/pricing'>See Pricing</Link>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                </>
            );
        } else {
            return (
                <div className='self-center'>
                <h2 className="mb-2 text-lg font-semibold text-gray-900">Enabling HTTPS on your localhost...</h2>
                <ul className="max-w-md space-y-2 list-inside">
                    <li className="flex items-center">
                        <svg aria-hidden="true" className="w-5 h-5 mr-1.5 text-primary flex-shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                        Account created
                    </li>
                    <li className="flex items-center">
                        <svg aria-hidden="true" className="w-5 h-5 mr-1.5 text-primary flex-shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                        Domain name registered
                    </li>
                    <li className="flex items-center">
                        <div role="status">
                            <svg aria-hidden="true" className="w-5 h-5 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-primary" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                            <span className="sr-only">Loading...</span>
                        </div>
                        Preparing your certificate
                    </li>
                    <li className="flex items-center mt-4 text-xs">
                        <p>
                        <i>
                            We are working on your certificate... <br/>
                            You can come back a bit later or just wait here. It should not take long.  <br/>
                            We also send you an e-mail once the certificate is ready.
                            <br/><br/>
                            Still here? Take a look in our <a href="/faq" target={"_blank"} className="link link-underline">guides and FAQ</a>
                        </i>
                        <span className='ml-1'>🤓</span>
                        </p>
                    </li>
                </ul>
                </div>
            );
        }

      }

}

export default Domains