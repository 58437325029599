import React from "react"
import WrapperView from "./WrapperView"

const Home = ({user}) => {
  const { displayName, email, emailVerified } = user;

  return (
    <WrapperView title="Your Profile">
      <ul>
        <li>Display name: {displayName}</li>
        <li>Email address: {email}</li>
      </ul>
    </WrapperView>
  )
}

export default Home
