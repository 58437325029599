import React from "react"
import Domains from "./Domains";
import WrapperViewDomains from "./WrapperViewDomains";

const Details = ({ user, database }) => {
  //const { user, database } = props;
  //console.log("det", database)

  const { displayName, email, emailVerified } = user;
  
  return (
    <WrapperViewDomains title="Your domains">
      <Domains database={database} user={user} />
    </WrapperViewDomains>
  )
}

export default Details
