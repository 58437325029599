import React from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"

const PrivateRoute = ({ component: Component, location, user, database, ...rest }) => {
    if (!user) {
    // If we’re not logged in, redirect to the login page.
    navigate(`/app/login`, { replace: true })
    return null
  }

  return <Component user={user} database={database} {...rest} />
}

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired
}

export default PrivateRoute
