import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/Layout"
import Details from "../components/Details"
import Home from "../components/Home"
import Login from "../components/Login"
import PrivateRoute from "../components/PrivateRoute"
import Status from "../components/Status"
import Index from "."
import FaqPage from "./faq"
import Pricing from "./pricing"
import ThankYou from "./thankyou"

const App = (props) => (
  <Layout {...props}>
    <Status auth={props.auth} user={props.user} />
    <Router>
      <Index {...props} path="/" />
      <ThankYou {...props} path="/thankyou" />
      <FaqPage {...props} path="/faq" />
      <Pricing {...props} path="/pricing" />
      <Login {...props} path="/app/login" />
      <PrivateRoute user={props.user} path="/app/profile" component={Home} />
      <PrivateRoute user={props.user} database={props.database} path="/app/domains" component={Details} />
    </Router>
  </Layout>
)

export default App
