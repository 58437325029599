import React from 'react';
import { Tabs } from 'react-daisyui'
import CopyButton from './CopyButton';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import WhatNow from './WhatNow';
import { ref, update } from 'firebase/database';
import Cookies from 'universal-cookie';

class DomainEntry extends React.Component {

    constructor(props) {
        //console.log("DomainEntry", props)
        super(props)
        this.state = {
          tabIndex: 0,
          firstOpen: true,
          editable: false,
        }
    }
      
    //componentDidMount = () => {}

    downloadAll = () => {
        const zip = new JSZip()
        const folder = zip.folder(this.props.domainInfo.name);
        folder.file('cert.pem', this.props.domainInfo.cert);
        folder.file('key.pem', this.props.domainInfo.key);
        folder.file('ca_bundle.pem', this.props.domainInfo.ca);
        folder.file('fullchain.pem', this.props.domainInfo.fullchain);
        zip.generateAsync({ type: 'blob' }).then(function (content) {
            FileSaver.saveAs(content, 'download.zip');
        });
    }

    mouseOver = (e) => {
        //console.log(e);
        if (e.target.id !== "dcb_" + this.props.index) {
            return
        }
        if (this.state.firstOpen) this.setState({firstOpen: false})
        //console.log(e.target.parentElement.classList); 
        e.target.parentElement.classList.remove("collapse-open")
        e.target.checked = true
        //console.log(e.target.parentElement.classList); 
        //e.target.parentElement.onMouseOver = null
        e.target.parentElement.removeEventListener("mouseover", this.mouseOver)
    }

    render() {
        const domainInfo  = this.props.domainInfo;
        //console.log("render", this.props.key);

        //if it was force open ( == the only domain for the user), then we set a cookie to not force open it next time to make the pricing window visible
        if (this.props.forceOpen === true) {
            console.log("set doNotForceOpen cookie");
            const cookies = new Cookies()
            cookies.set("doNotForceOpen", true);
        }

        if (domainInfo) {
            return (
                <div onMouseOver={this.props.forceOpen && this.state.firstOpen ? this.mouseOver : null} className={"collapse collapse-arrow " + (this.props.forceOpen === true && this.state.firstOpen ? "collapse-open" : "" ) }>
                    <input type="checkbox" id={"dcb_" + this.props.index} /> 
                    <div className={"collapse-title text-l font-medium flex flex-row justify-start border-b-[1px] border-b-gray-200 " + (this.props.index / 2 === 0 ? "" : "bg-base-200" )}>
                        <div className="basis-3/8 min-w-[40%]">
                            {domainInfo.name}
                            <CopyButton className="relative z-10" iconOnly={true} textToCopy={domainInfo.name} />
                        </div>
                        <div className="grow-0 basis-1/8 shrink-0 min-w-[10%] text-center">
                            -->
                        </div>
                        <div className="basis-3/8 min-w-[40%] text-center">
                            
                            {
                                !this.state.editable 
                                ? domainInfo.redirect 
                                : <input id={'domain_input' + this.props.index}
                                     type={"text"} 
                                     placeholder="Local IP" 
                                     defaultValue={domainInfo.redirect} 
                                     className="input input-bordered input-primary input-sm w-full max-w-xs relative z-10 text-center enabled" 
                                     />
                            }
                        </div>
                        <div className="grow-0 basis-1/8 min-w-[10%] text-center flex flow-row flex-wrap">
                            {this.state.editable && <button className="btn btn-success btn-xs relative z-10" onClick={(e)=>{
                                const newVal = document.getElementById("domain_input" + this.props.index).value;
                                const refi = ref(this.props.database, "users/" + this.props.user.uid + "/domains/" + this.props.domainKey)
                                update(refi, {"redirect" : newVal}).catch((error) => {
                                    alert("Updating your redirect IP failed. Please re-check your IPv4 address and try again!")
                                  })

                                this.setState({editable: !this.state.editable})
                            }}>
                                SAVE
                            </button>}
                            <button className="btn btn-xs relative z-10" onClick={(e)=>{
                                this.setState({editable: !this.state.editable})
                            }}>
                                {this.state.editable ? "CANCEL" : "EDIT"}
                            </button>
                        </div>
                    </div>

                    <div className="collapse-content">
                        <br/> 
                        <div className='flex flex-wrap'>
                            <div id="panel_certs">
                                <Tabs boxed 
                                    value={this.state.tabIndex} 
                                    style={{width: 'fit-content'}} 
                                    onChange={(v)=>{
                                        if (v !== 99) {
                                            this.setState({ tabIndex: v })
                                        } else {
                                            this.downloadAll()
                                        }
                                    }
                                    }>
                                    <Tabs.Tab value={0}>Private key</Tabs.Tab>
                                    <Tabs.Tab value={1}>Certificate</Tabs.Tab>
                                    <Tabs.Tab value={2}>CA Bundle</Tabs.Tab>
                                    <Tabs.Tab value={3}>Full chain</Tabs.Tab>
                                    <Tabs.Tab className="tab-active" style={{border: 'outset'}} value={99}>Download all</Tabs.Tab>
                                </Tabs>
                                <br></br>
                                {
                                    this.state.tabIndex === 0 && <div className="mockup-code text-sm" style={{width: 'fit-content', 'paddingLeft': '18px'}}>
                                        <pre className="bg-success text-warning-content text-center">
                                            <CopyButton textToCopy={domainInfo.key} />
                                        </pre>
                                        <br/>
                                        <pre><code>{domainInfo.key}</code></pre>
                                    </div>
                                }
                                {
                                    this.state.tabIndex === 1 && <div className="mockup-code text-sm" style={{width: 'fit-content', 'paddingLeft': '18px'}}>
                                        <pre className="bg-success text-warning-content text-center">
                                            <CopyButton textToCopy={domainInfo.cert} />
                                        </pre>
                                        <br/>
                                        <pre><code>{domainInfo.cert}</code></pre>
                                    </div>
                                }
                                {
                                    this.state.tabIndex === 2 && <div className="mockup-code text-sm" style={{width: 'fit-content', 'paddingLeft': '18px'}}>
                                        <pre className="bg-success text-warning-content text-center">
                                            <CopyButton textToCopy={domainInfo.ca} />
                                        </pre>
                                        <br/>
                                        <pre><code>{domainInfo.ca}</code></pre>
                                    </div>
                                }
                                {
                                    this.state.tabIndex === 3 && <div className="mockup-code text-sm" style={{width: 'fit-content', 'paddingLeft': '18px'}}>
                                        <pre className="bg-success text-warning-content text-center">
                                            <CopyButton textToCopy={domainInfo.fullchain} />
                                        </pre>
                                        <br/>
                                        <pre><code>{domainInfo.fullchain}</code></pre>
                                    </div>
                                }
                            </div>
                            <div id="panel_how-to-next" className='max-w-[100%] l:max-w-[50%] self-center mt-4 xl:mt-[4em] xl:m-auto'>
                                <WhatNow title="What now?" domain={domainInfo.name} />
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div>Loading...</div>
            );
        }

      }

}

export default DomainEntry