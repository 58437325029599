import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import View from "../components/View"
import Status from "../components/Status"

const Index = (props) => (
  <Layout {...props}>
    <Status auth={props.auth}  user={props.user}  />
    <View title="">
      <div className="hero min-h-screen bg-base-200">
        <div className="hero-content flex-col lg:flex-row-reverse max-w-4xl">
          <img src="https.png" className="max-w-xs rounded-lg" />
          <div>
            <h1 className="text-5xl font-bold">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2.5} stroke="currentColor" className="w-12 h-12 inline align-top">
              <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
            </svg>
              SSL on <code className="text-5xl rounded-lg bg-primary px-2 py-1 align-middle">localhost</code>!
            </h1>
            <p className="py-6 font-medium text-2xl">
              ✋ Stop debugging in production. <br/>
              🤯 No more hassle with self-signed certificates.</p>
            <Link to="/app/domains"><button className="btn btn-primary">Get Started</button></Link>
          </div>
        </div>
      </div>
    </View>
  </Layout>
)

export default Index

export const Head = ({ location, params, data, pageContext }) => {
  return (
  <>
    <title>RedirectTo.Me</title>
    <meta name="description" content="No more hassle with self-signed certificates." />
    <meta name="keywords" content="security, ssl, tls, certificate, localhost, express, expressjs, node, nodejs" />

      <meta property="og:type" content="website" />
      <meta property="og:url" content={`https://redirectto.me`} />
      <meta property="og:title" content="RedirectTo.Me" />
      <meta property="og:description" content="No more hassle with self-signed certificates." />
      <meta property="og:image" content="https://redirectto.me/domain_logo_green.png" />

      <meta property="twitter:card" content="summary" />
      <meta property="twitter:url" content={`https://redirectto.me`} />
      <meta property="twitter:title" content="RedirectTo.Me" />
      <meta property="twitter:description" content="No more hassle with self-signed certificates." />
  </>
  )
}