import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const WhatNow = ({ title, domain}) => (
  <section className="">
    <div className="card shadow-l bg-primary text-primary-content">
      <div className="card-body">
        <h1 className="card-title text-2xl my-8">{title}</h1>
          <div className="mb-4">
              1. Download your <b>package (all) or copy your private key and certificate</b>.
          </div>
          <div className="mb-4">
              2. <b>Host your HTTPS server locally</b>.
              <p className="ml-4 mt-2">
                You can just follow our <Link to={`/faq/how-to-expressjs-ssl`} className="link link-underline">How to configure SSL certificate with Express.JS for an HTTPS server</Link> guide.
              </p>
          </div>
          <div className="mb-4">
              3. <b>Navigate</b> to <a className="link" href={"https://" + domain + ":8001"}>https://{domain}:8001</a> (port is 8001, if you have followed our guide).
          </div>
          <div className="mb-4">
              4. <i>(optional)</i> <b>Change your redirect IP</b> from localhost (127.0.0.1) to your LAN IP.
              <p className="ml-4 mt-2">
                This is useful if you need to access your server on local network.
              </p>
          </div>
          <div className="mb-4">
              5. <b>Have fun! 👋</b>
          </div>
          <div className="mb-4 mt-4">
              <i>More questions? Head over to our <Link to={`/faq`} className="link link-underline">FAQ page</Link>!</i>
          </div>
      </div>
    </div>
  </section>
)

WhatNow.propTypes = {
  title: PropTypes.string.isRequired,
}

export default WhatNow
