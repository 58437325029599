import React from "react"
import PropTypes from "prop-types"
import * as styles from "./wrapperview_domains.module.css"

const WrapperViewDomains = ({ title, children }) => (
  <section className={styles["wrapperview"]}>
    <div className="card w-xl bg-base-100 shadow-xl xl:mx-8 xl:my-8">
      <div className="card-body">
        <h1 className="card-title text-2xl my-8">{title}</h1>
        {children}
      </div>
    </div>
  </section>
)

WrapperViewDomains.propTypes = {
  title: PropTypes.string.isRequired,
}

export default WrapperViewDomains
