import React from 'react';
import { Button } from 'react-daisyui'

class CopyButton extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
          copied: false,
        }
    }
      
    //componentDidMount = () => {}

    copyToClipboard = () => {
        navigator.clipboard.writeText(this.props.textToCopy).then(
          () => {
            this.setState({copied: true});
            // changing back to default state after 2 seconds.
            setTimeout(() => {
                this.setState({ copied: false });
            }, 1000);
          },
          (err) => {
            console.log("failed to copy", err.mesage);
          }
        );
      };

    render() {
            let iconOnly = this.props.iconOnly
            return (
                iconOnly ?
                  <Button className="relative z-10" color={this.state.copied ? "primary" : "ghost"} size='xs' onClick={() => this.copyToClipboard()}>
                    <svg className="inline mr-1" width="16" height="16" viewBox="0 0 16 16"><title>Click to copy</title><path d="M12,2h.5A1.5,1.5,0,0,1,14,3.5v10A1.5,1.5,0,0,1,12.5,15h-9A1.5,1.5,0,0,1,2,13.5V3.5A1.5,1.5,0,0,1,3.5,2H4" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="1"></path><rect x="6" y="1" width="4" height="2" fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"></rect><polyline className="copy-to-clip__icon-check" points="5 9 7 11 11 7" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"></polyline></svg>
                  </Button>  
                  :
                  <Button className="px-8" color="ghost" size='xs' onClick={() => this.copyToClipboard()}>
                      <svg className="inline mr-1" width="16" height="16" viewBox="0 0 16 16"><title>Click to copy</title><path d="M12,2h.5A1.5,1.5,0,0,1,14,3.5v10A1.5,1.5,0,0,1,12.5,15h-9A1.5,1.5,0,0,1,2,13.5V3.5A1.5,1.5,0,0,1,3.5,2H4" fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="1"></path><rect x="6" y="1" width="4" height="2" fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"></rect><polyline className="copy-to-clip__icon-check" points="5 9 7 11 11 7" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"></polyline></svg>
                      {!this.state.copied && <code>Copy to clipboard</code>}
                      {this.state.copied && <code>Copied!</code>}
                  </Button>   
                             
            );
      }

}

export default CopyButton