import { EmailAuthProvider, GithubAuthProvider, GoogleAuthProvider } from 'firebase/auth';
import { navigate } from 'gatsby';
import React from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from "../services/firebase";


class SignInScreen extends React.Component {

  render() {
    const { auth } = this.props;
    // Configure FirebaseUI.
    const uiConfig = {
      // One of: ['popup', 'redirect']
      signInFlow: 'popup',
      // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
      signInSuccessUrl: '/app/domains',
      // We will display Google and Facebook as auth providers.
      signInOptions: [
        GoogleAuthProvider.PROVIDER_ID,
        GithubAuthProvider.PROVIDER_ID,
        EmailAuthProvider.PROVIDER_ID
      ],
      callbacks: {
        signInSuccessWithAuthResult: function(authResult, redirectUrl) {
          //console.log("callbacks signInSuccessWithAuthResult", authResult, redirectUrl)
          // User successfully signed in.
          // Return type determines whether we continue the redirect automatically
          // or whether we leave that to developer to handle.
          navigate("/app/domains");
          return false;
        },
        signInFailure: function(error) {
          //console.log("callbacks error", error)
        },
        uiShown: function() {
          //console.log("callbacks: ui shown")
        }
      }
    };
    
    return (
      <div>
        <p>Please sign-in:</p>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} uiCallback={(ui)=>{/*console.log("ui", ui)*/}}/>
      </div>
    );
  }
}

export default SignInScreen;